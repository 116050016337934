import React from 'react';
import {
    Box,
    Card,
    CardContent,
    Typography,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
} from "@mui/material";
import { styled } from '@mui/material/styles';
import dayjs from 'dayjs';
import CheckIcon from '@mui/icons-material/Check';

// Styled components using MUI's styled API
const MatchContent = styled(CardContent)(({ theme }) => ({
    padding: '8px !important',
}));

const Player = styled(Box, {
    shouldForwardProp: prop => prop !== 'winner'
})(({ winner, theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px',
    backgroundColor: 'transparent',
    borderRadius: '4px',
    position: 'relative',
    '& > span:first-of-type': {
        fontWeight: winner ? 500 : 400,
    }
}));

const DateTime = styled(Box)(({ theme }) => ({
    fontSize: '0.85em',
    color: theme.palette.text.secondary,
    marginBottom: '8px',
    backgroundColor: '#eceef0',
    padding: '8px',
    borderRadius: '4px',
    minHeight: '35px'
}));

const Location = styled('span')(({ theme }) => ({
    fontSize: '0.85em',
    color: theme.palette.text.secondary,
    float: 'right',
}));

const TagContainer = styled(Box)({
    display: 'flex',
    gap: '8px',
    marginBottom: '12px',
});

const Tag = styled(Box)(({ theme }) => ({
    display: 'inline-block',
    padding: '4px 12px',
    backgroundColor: theme.palette.grey[50],
    color: theme.palette.grey[600],
    borderRadius: '16px',
    fontSize: '0.75em',
    letterSpacing: '0.4px',
    fontWeight: 500,
    border: `1px solid ${theme.palette.grey[200]}`,
    textTransform: 'uppercase',
}));

const CategoryTag = styled(Tag)({});

const RoundTag = styled(Tag)(({ theme }) => ({
    backgroundColor: theme.palette.primary[50],
    color: theme.palette.primary[700],
    border: `1px solid ${theme.palette.primary[200]}`,
}));

const Score = styled('div')({
    display: 'flex',
    gap: '4px',
    alignItems: 'center',
    minWidth: '80px',
    justifyContent: 'flex-end'
});

const ScoreNumber = styled('span')({
    minWidth: '16px',
    textAlign: 'center',
});

const Seed = styled('span')(({ theme }) => ({
    color: theme.palette.text.secondary,
    fontSize: '0.9em',
    marginLeft: '4px',
}));

const WinnerCheck = styled(CheckIcon)(({ theme }) => ({
    color: theme.palette.success.main,
    fontSize: '16px',
    marginRight: '4px',
}));

const PlayerName = styled('span')({
    display: 'flex',
    alignItems: 'center',
});

export const ScheduleView = ({ matches = {} }) => {
    const [selectedStudent, setSelectedStudent] = React.useState('');

    // Extract all unique students from matches
    const allStudents = React.useMemo(() => {
        const students = new Set();
        Object.entries(matches).forEach(([category, categoryData]) => {
            Object.values(categoryData).forEach(drawData => {
                Object.keys(drawData).forEach(round => {
                    drawData[round].forEach(match => {
                        if (match.player1?.name !== 'BYE' && match.player1?.name !== 'TBD') {
                            students.add(match.player1.name);
                        }
                        if (match.player2?.name !== 'BYE' && match.player2?.name !== 'TBD') {
                            students.add(match.player2.name);
                        }
                    });
                });
            });
        });
        return Array.from(students).sort();
    }, [matches]);

    // Get all matches for display
    const getFilteredMatches = React.useCallback(() => {
        const filteredMatches = [];
        Object.entries(matches).forEach(([category, categoryData]) => {
            Object.values(categoryData).forEach(drawData => {
                Object.keys(drawData).forEach(round => {
                    drawData[round].forEach(match => {
                        const isRelevantMatch = !selectedStudent ||
                            match.player1.name === selectedStudent ||
                            match.player2.name === selectedStudent;

                        if (isRelevantMatch) {
                            filteredMatches.push({
                                ...match,
                                category,
                                round
                            });
                        }
                    });
                });
            });
        });

        return filteredMatches.sort((a, b) => {
            return new Date(a.datetime || '9999-12-31') - new Date(b.datetime || '9999-12-31');
        });
    }, [matches, selectedStudent]);

    const renderPlayer = (player) => (
        <Player winner={player.winner}>
            <PlayerName>
                {player.name}
                {player.seed && <Seed>[{player.seed}]</Seed>}
            </PlayerName>
            <Score>
                {player.winner && <WinnerCheck />}
                {player.score?.map((s, i) => (
                    <ScoreNumber key={i}>{s}</ScoreNumber>
                ))}
                {player.walkover && <span>W/O</span>}
                {player.bye && <span>BYE</span>}
            </Score>
        </Player>
    );

    const filteredMatches = getFilteredMatches();

    return (
        <Box sx={{ maxWidth: '800px', margin: '0 auto', padding: '20px' }}>
            <FormControl fullWidth sx={{ marginBottom: 3 }}>
                <InputLabel>Select Participant</InputLabel>
                <Select
                    value={selectedStudent}
                    onChange={(e) => setSelectedStudent(e.target.value)}
                    label="Filter by Student"
                >
                    <MenuItem value="">All Students</MenuItem>
                    {allStudents.map(student => (
                        <MenuItem key={student} value={student}>{student}</MenuItem>
                    ))}
                </Select>
            </FormControl>

            {filteredMatches.length === 0 ? (
                <Typography
                    align="center"
                    color="textSecondary"
                    sx={{ padding: 2.5 }}
                >
                    {selectedStudent
                        ? `No scheduled matches found for ${selectedStudent}`
                        : 'No scheduled matches found'}
                </Typography>
            ) : (
                <Box>
                    {filteredMatches.map((match, index) => (
                        <Card
                            key={index}
                            sx={{
                                backgroundColor: 'background.paper',
                                marginBottom: 2,
                                boxShadow: 1
                            }}
                        >
                            <MatchContent>
                                <TagContainer>
                                    <CategoryTag>{match.category}</CategoryTag>
                                    <RoundTag>{match.round}</RoundTag>
                                </TagContainer>
                                <DateTime>
                                    {match.player1?.name !== 'BYE' && match.player2?.name !== 'BYE' && (
                                        <>
                                            {match.datetime
                                                ? dayjs(match.datetime).format('ddd, MMM D, h:mm A')
                                                : 'Time TBD'}
                                            <Location>{match.location || 'Court TBD'}</Location>
                                        </>
                                    )}
                                </DateTime>
                                <Box>
                                    {renderPlayer(match.player1)}
                                    {renderPlayer(match.player2)}
                                </Box>
                            </MatchContent>
                        </Card>
                    ))}
                </Box>
            )}
        </Box>
    );
};